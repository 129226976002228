import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import ParcelsFiltersSheet from '@/components/layout/parcels/ParcelsFiltersSheet.tsx';
import ParcelsTableSection from '@/components/layout/parcels/ParcelsTableSection.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import {
  deleteParcelById,
  getFilteredParcels,
} from '@/redux/parcels/parcels.actions.ts';
import {
  CreateParcelResponse,
  GetParcelsResponse,
} from '@/redux/parcels/parcels.types.ts';

type FilterObject = {
  realPage: number;
  realRowsPerPage: number;
  realSortColumn: string;
  realSortAsc: boolean;
  realInputText: string;
  realSelectedCountry: string;
  realSelectedPlace: string;
};

const ParcelsPage: FC = () => {
  const router = useNavigate();

  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<string>('');

  const createQueryParams = (filterObject: FilterObject) => {
    const {
      realPage,
      realRowsPerPage,
      realSortColumn,
      realSortAsc,
      realInputText,
      realSelectedCountry,
      realSelectedPlace,
    } = filterObject;
    return `?distributorId=${localStorage.getItem('id')}&page=${realPage}&perPage=${realRowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}${realSelectedCountry ? `&country=${realSelectedCountry}` : ''}${realSelectedPlace ? `&place=${realSelectedPlace}` : ''}`;
  };

  const callFetchParcels = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredParcels(queryParams)).unwrap();
  };

  const handleErrorResponse = (
    response: GetParcelsResponse | CreateParcelResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSelectedCountry: selectedCountry,
      realSortAsc: isSortAsc,
      realInputText: value,
      realSortColumn: sortColumn,
      realSelectedPlace: selectedPlace,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchParcels(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const filterObject: FilterObject = {
        realPage: page,
        realRowsPerPage: rowsPerPage,
        realSelectedCountry: selectedCountry,
        realSortAsc: !isSortAsc,
        realInputText: inputText,
        realSortColumn: sortColumn,
        realSelectedPlace: selectedPlace,
      };
      const queryParams = createQueryParams(filterObject);
      await callFetchParcels(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSelectedCountry: selectedCountry,
      realSortAsc: true,
      realInputText: inputText,
      realSortColumn: sortColumn,
      realSelectedPlace: selectedPlace,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchParcels(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const filterObject: FilterObject = {
      realPage: selectedPage,
      realRowsPerPage: rowsPerPage,
      realSelectedCountry: selectedCountry,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSortColumn: sortColumn,
      realSelectedPlace: selectedPlace,
    };
    const queryParams = createQueryParams(filterObject);
    setPage(selectedPage);
    await callFetchParcels(queryParams);
  };

  const fetchParcels = async () => {
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSelectedCountry: selectedCountry,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSortColumn: sortColumn,
      realSelectedPlace: selectedPlace,
    };
    const queryParams = createQueryParams(filterObject);
    const response = await callFetchParcels(queryParams);
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  const onDeleteParcelClick = async (parcelId: string) => {
    // @ts-ignore
    const response = await dispatch(deleteParcelById(parcelId)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    await fetchParcels();
    toast.success('Parcela je uspešno izbrisana');
  };

  const filterUpdateToChange: Record<
    string,
    (value: ((prevState: string) => string) | string) => void
  > = {
    realSelectedCountry: setSelectedCountry,
    realSelectedPlace: setSelectedPlace,
  };

  const onFilterChange = async (filter: string, value: string) => {
    filterUpdateToChange[filter](value);
    setPage(0);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
    };

    filterObject[filter] = value;
    const queryParams = createQueryParams(filterObject);
    await callFetchParcels(queryParams);
  };

  const onResetFilters = async () => {
    setSelectedCountry('');
    setSelectedPlace('');
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: '',
      realSelectedPlace: '',
    };

    const queryParams = createQueryParams(filterObject);
    await callFetchParcels(queryParams);
  };

  const onRowsPerPageChange = async (value: number) => {
    setPage(0);
    setRowsPerPage(value);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: value,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
    };

    const queryParams = createQueryParams(filterObject);
    await callFetchParcels(queryParams);
  };

  useEffect(() => {
    fetchParcels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput handleChange={handleChange} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 sm:mt-5">
          <div className="flex items-center gap-2">
            <ParcelsFiltersSheet
              selectedCountry={selectedCountry}
              selectedPlace={selectedPlace}
              onFilterChange={onFilterChange}
              resetFilters={onResetFilters}
            />
            <AddNewIcon link="/parcels/create" text="Dodajte parcelu" />
          </div>
          <ParcelsTableSection
            page={page}
            rowsPerPage={rowsPerPage}
            onColumnClick={onColumnClick}
            onPageChangeClick={onPageChangeClick}
            onDeleteParcel={onDeleteParcelClick}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ParcelsPage;
