import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  didByDeviceId,
  occupiedDidsWithoutDevice,
  setDids,
  updatedDid,
} from '@/redux/did/did.slice.ts';
import {
  AllDidsResponse,
  GetDidByDeviceIdResponse,
  UpdateDid,
  UpdateDidResponse,
} from '@/redux/did/did.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getDids = createAsyncThunk<AllDidsResponse, string>(
  'distributor/get-dids',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<AllDidsResponse>(
        `/distributor/did/get-all${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }
      dispatch(setDids(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateDid = createAsyncThunk<UpdateDidResponse, UpdateDid>(
  'distributor/update-did',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.put<UpdateDidResponse>(
        `/distributor/did/update`,
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(updatedDid(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getOccupiedDidsWithoudDevice = createAsyncThunk<AllDidsResponse>(
  'distributor/get-occupied-dids-without-device',
  async (_, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<AllDidsResponse>(
        `/distributor/did/get-occupied-dids-without-device`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(occupiedDidsWithoutDevice(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getDidByDeviceId = createAsyncThunk<GetDidByDeviceIdResponse, string>(
  'distributor/get-did-by-device-id',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetDidByDeviceIdResponse>(
        `/distributor/did/get-did-device-id?deviceId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(didByDeviceId(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getDIDByDid = createAsyncThunk<GetDidByDeviceIdResponse, string>(
  'distributor/get-did-by-did',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<GetDidByDeviceIdResponse>(
        `/distributor/did/get-did-by-did?did=${reqData}`
      );

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export {
  getDids,
  updateDid,
  getOccupiedDidsWithoudDevice,
  getDidByDeviceId,
  getDIDByDid,
};
