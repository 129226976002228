import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { Label } from '@/components/ui/label.tsx';
import { distributorLogin } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { loginSchema } from '@/zod/types.ts';

export type LoginFormData = {
  email: string;
  password: string;
};

const LoginForm: FC = () => {
  const loginForm = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onSubmit = async (data: LoginFormData) => {
    // @ts-ignore
    const response = await dispatch(distributorLogin(data)).unwrap();

    if (!response.success) {
      loginForm.setError('password', { message: 'Greška prilikom prijave' });
      return;
    }

    router('/');
  };

  return (
    <Form
      reset={loginForm.reset}
      formState={loginForm.formState}
      clearErrors={loginForm.clearErrors}
      control={loginForm.control}
      getFieldState={loginForm.getFieldState}
      getValues={loginForm.getValues}
      handleSubmit={loginForm.handleSubmit}
      register={loginForm.register}
      resetField={loginForm.resetField}
      setError={loginForm.setError}
      setFocus={loginForm.setFocus}
      setValue={loginForm.setValue}
      trigger={loginForm.trigger}
      unregister={loginForm.unregister}
      watch={loginForm.watch}
    >
      <form
        className="grid gap-4 px-2"
        onSubmit={loginForm.handleSubmit(onSubmit)}
      >
        <div className="grid gap-2">
          <CustomFormInput
            customForm={loginForm}
            name="email"
            labelText="Email"
            formItemStyle="flex flex-col max-md:flex-col w-full"
            labelStyle=""
            divStyle="w-full"
            type="text"
            placeholder="Email"
            inputStyle="rounded-lg focus-visible:border-[#2b90d9] focus-visible:ring-0"
          />
        </div>
        <div className="grid gap-2">
          <div className="grid">
            <div className="flex items-center">
              <Label htmlFor="password">Lozinka</Label>
              <Link
                to="/forgot-password"
                className="ml-auto inline-block text-sm underline hover:text-[#2b90d9]"
              >
                Zaboravili ste lozinku?
              </Link>
            </div>
            <CustomFormInput
              customForm={loginForm}
              name="password"
              labelText=""
              formItemStyle="flex flex-col max-md:flex-col w-full "
              labelStyle=""
              divStyle="w-full"
              type="password"
              placeholder="Lozinka"
              inputStyle="rounded-lg focus-visible:border-[#2b90d9] focus-visible:ring-0"
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            type="submit"
            className="bg-[#2b90d9] px-2 py-3 text-white min-w-[100px] text-sm"
          >
            Prijava
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
