import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddUserForm from '@/components/forms/AddUserForm.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.actions.ts';
import { GetOrganizationsResponse } from '@/redux/organizations/organizations.types.ts';

const CreateUserPage = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetOrganizationsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchOrganizationsForCombobox = async () => {
      const response = await dispatch(
        getOrganizations(localStorage.getItem('id'))
      ).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchOrganizationsForCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 md:px-6 md:py-0 md:gap-8">
          <Card x-chunk="create-user-card">
            <CardHeader>
              <CardTitle>Novi Korisnik</CardTitle>
            </CardHeader>
            <CardContent>
              <AddUserForm />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreateUserPage;
