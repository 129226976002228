import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SensDeviceInfoForm from '@/components/forms/devices/SensDeviceInfoForm.tsx';
import SensDeviceSensorsForm from '@/components/forms/devices/SensDeviceSensorsForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { createSensDevice } from '@/redux/device/devices.actions.ts';
import { getAvailableZones } from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createSensDeviceSchema } from '@/zod/types.ts';

export type CreateSensDeviceFormData = {
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: string[];
  standbyModeTime: string;
  sensSensorType: string;
  humidityOne: string;
  humidityTwo: string;
  flowMeter: string;
  soilTemperature: string;
  soilSalinity: string;
  soilPH: string;
  airTemperature: string;
  airHumidity: string;
  windDirection: string;
  windSpeed: string;
  solarRadiation: string;
  amountOfPrecipitation: string;
  batteryVoltage: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  batteryType: string;
};

const CreateSensDeviceForm: FC = () => {
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(createSensDeviceSchema),
    defaultValues: {
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      zonesForDevice: [],
      standbyModeTime: '0',
      sensSensorType: '30',
      humidityOne: 'true',
      humidityTwo: 'false',
      flowMeter: 'true',
      soilTemperature: 'true',
      soilSalinity: 'true',
      soilPH: 'true',
      airTemperature: 'false',
      airHumidity: 'false',
      windDirection: 'false',
      windSpeed: 'false',
      solarRadiation: 'false',
      amountOfPrecipitation: 'false',
      batteryVoltage: 'true',
      notificationForWrongFlowMeter: 'false',
      pressureSensor: 'false',
      batteryType: '',
    },
  });
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const multiSelectData = availableZones.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(`/devices/${params.parcelId}`);
  };

  const onSubmit = async (data: CreateSensDeviceFormData) => {
    // @ts-ignore
    const response = await dispatch(createSensDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno dodat uredjaj');
    router(`/devices/${params.parcelId}`);
  };

  const handleErrorAccordion = () => {
    const { errors } = form.formState;

    if (Object.keys(errors).length === 0) {
      return;
    }

    if (
      errors.name ||
      errors.standbyModeTime ||
      errors.batteryType ||
      errors.zonesForDevice ||
      errors.color
    ) {
      setActiveAccordion('infoForm');

      return;
    }

    setActiveAccordion('sensorsForm');
  };

  const handleFormSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }

    form.handleSubmit(onSubmit)();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <SensDeviceInfoForm
                form={form}
                multiSelectData={multiSelectData}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <SensDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Dodaj uredjaj"
        />
      </form>
    </Form>
  );
};

export default CreateSensDeviceForm;
