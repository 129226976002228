import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { distributorForgotPassword } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { forgotPasswordSchema } from '@/zod/types.ts';

export type ForgotPasswordFormData = {
  email: string;
};

type ForgotPasswordFormContentProps = {
  forgotPasswordForm: UseFormReturn<any, any, any>;
  onSubmit: (data: ForgotPasswordFormData) => void;
};

const ForgotPasswordFormContent: FC<ForgotPasswordFormContentProps> = ({
  forgotPasswordForm,
  onSubmit,
}) => {
  return (
    <Form
      reset={forgotPasswordForm.reset}
      formState={forgotPasswordForm.formState}
      clearErrors={forgotPasswordForm.clearErrors}
      control={forgotPasswordForm.control}
      getFieldState={forgotPasswordForm.getFieldState}
      getValues={forgotPasswordForm.getValues}
      handleSubmit={forgotPasswordForm.handleSubmit}
      register={forgotPasswordForm.register}
      resetField={forgotPasswordForm.resetField}
      setError={forgotPasswordForm.setError}
      setFocus={forgotPasswordForm.setFocus}
      setValue={forgotPasswordForm.setValue}
      trigger={forgotPasswordForm.trigger}
      unregister={forgotPasswordForm.unregister}
      watch={forgotPasswordForm.watch}
    >
      <form
        className="grid gap-4 px-2"
        onSubmit={forgotPasswordForm.handleSubmit(onSubmit)}
      >
        <div className="grid gap-2">
          <CustomFormInput
            customForm={forgotPasswordForm}
            name="email"
            labelText="Email"
            formItemStyle="flex flex-col max-md:flex-col w-full"
            labelStyle=""
            divStyle="w-full"
            type="text"
            placeholder="Email"
            inputStyle="rounded-lg focus-visible:border-[#2b90d9] focus-visible:ring-0"
          />
        </div>
        <div className="flex items-center justify-center">
          <Button
            type="submit"
            className="bg-[#2b90d9] px-2 py-3 text-white min-w-[100px] text-sm"
          >
            Pošalji
          </Button>
        </div>
      </form>
    </Form>
  );
};

const ForgotPasswordForm: FC = () => {
  const [isMailSent, setIsMailSent] = useState(false);
  const forgotPasswordForm = useForm<ForgotPasswordFormData>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = async (data: ForgotPasswordFormData) => {
    // @ts-ignore
    const response = await dispatch(distributorForgotPassword(data)).unwrap();

    if (!response.success) {
      forgotPasswordForm.setError('email', {
        message: 'Zadata email adresa ne postoji u sistemu',
      });
      return;
    }

    setIsMailSent(true);
  };

  const sendAgainClick = async () => {
    await onSubmit(forgotPasswordForm.getValues());
    toast.success('Email ponovo poslat');
  };

  return (
    <>
      {!isMailSent && (
        <ForgotPasswordFormContent
          forgotPasswordForm={forgotPasswordForm}
          onSubmit={onSubmit}
        />
      )}
      {isMailSent && (
        <div className="px-5 flex flex-col gap-1 flex-1">
          <h1 className="text-[#007bff] text-xl">
            Na unetu email adresu biće Vam poslat link za izmenu lozinke.
          </h1>
          <span className="mt-10 text-base text-[#007bff]">
            Niste dobili mejl?
          </span>
          <div>
            <Button
              className="bg-[#007bff] py-3 px-5 text-white mt-3"
              type="button"
              onClick={sendAgainClick}
            >
              Pošalji email ponovo
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordForm;
