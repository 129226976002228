import { zodResolver } from '@hookform/resolvers/zod';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import TableCellCheckboxForm from '@/components/forms/delivery/controls/TableCellCheckboxForm.tsx';
import TableCellDropdownForm from '@/components/forms/delivery/controls/TableCellDropdownForm.tsx';
import TableCellInputForm from '@/components/forms/delivery/controls/TableCellInputForm.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { patchSENSDelivery } from '@/redux/device/devices.actions.ts';
import {
  DeviceRevision,
  GetSensDeviceResponse,
  PatchSENSRequest,
  SensDevice,
} from '@/redux/device/devices.types.ts';
import {
  getDidByDeviceId as getDidByDeviceIdAction,
  getOccupiedDidsWithoudDevice,
} from '@/redux/did/did.actions.ts';
import { getOccupiedDidsWithoutDevice } from '@/redux/did/did.selectors.ts';
import {
  AllDidsResponse,
  GetDidByDeviceIdResponse,
} from '@/redux/did/did.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  airTempAndHumidTypes,
  batteryTypesSENS,
  flowMeterTypes,
} from '@/utils/device.util.ts';
import { patchSENSSchema } from '@/zod/types.ts';

type DeliverySENSDeviceFormProps = {
  device: SensDevice;
  revisionsSENS: DeviceRevision[];
};

export type PatchSENSFormData = {
  id: string;
  rev: string;
  did: string;
  serialNumber: string;
  sentekTH30: boolean;
  sentekTHS30: boolean;
  sentekTH60: boolean;
  sentekTHS60: boolean;
  sentekTH90: boolean;
  sentekTHS90: boolean;
  airTempAndHumidType: string;
  dekagonHS1: boolean;
  dekagonHS2: boolean;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  batteryType: string;
  flowMeterType: string;
  soilPH: boolean;
  windSpeed: boolean;
  windDirection: boolean;
  amountOfPrecipitation: boolean;
  solarRadiation: boolean;
  commentOnEdit: string;
};

const DeliverySENSDeviceForm: FC<DeliverySENSDeviceFormProps> = ({
  device,
  revisionsSENS,
}) => {
  const occupiedDids = useAppSelector(getOccupiedDidsWithoutDevice);
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [didForDevice, setDidForDevice] = useState<string>('');
  const [didDropDown, setDidDropDown] = useState<DropDownItems[]>(
    occupiedDids.map((did) => {
      return {
        text: did.did,
        value: did.did,
      };
    })
  );
  const form = useForm({
    resolver: zodResolver(patchSENSSchema),
    defaultValues: {
      id: device.id,
      rev: device.additionalFields.rev,
      did: didForDevice,
      serialNumber: device.serialNumber ? device.serialNumber : '',
      sentekTH30: device.additionalFields.sentekTH30,
      sentekTHS30: device.additionalFields.sentekTHS30,
      sentekTH60: device.additionalFields.sentekTH60,
      sentekTHS60: device.additionalFields.sentekTHS60,
      sentekTH90: device.additionalFields.sentekTH90,
      sentekTHS90: device.additionalFields.sentekTHS90,
      dekagonHS1: device.additionalFields.dekagonHS1,
      dekagonHS2: device.additionalFields.dekagonHS2,
      soilPH: device.additionalSensorsValues.soilPH,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      batteryType: device.additionalFields.batteryType,
      airTempAndHumidType: device.additionalFields.airTempAndHumidType,
      windSpeed: device.additionalSensorsValues.windSpeed,
      windDirection: device.additionalSensorsValues.windDirection,
      amountOfPrecipitation:
        device.additionalSensorsValues.amountOfPrecipitation,
      solarRadiation: device.additionalSensorsValues.solarRadiation,
      commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    },
  });
  const { handleSubmit, watch } = form;

  const revisionDropdown: DropDownItems[] = revisionsSENS.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const handleErrorResponse = (
    response: GetSensDeviceResponse | GetDidByDeviceIdResponse | AllDidsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = async (data: PatchSENSFormData) => {
    const dataToSend: PatchSENSRequest = {
      device,
      dataToUpdate: data,
    };

    // @ts-ignore
    const response = await dispatch(patchSENSDelivery(dataToSend)).unwrap();
    setDidForDevice(data.did);

    const responseOccupied = await dispatch(
      getOccupiedDidsWithoudDevice()
    ).unwrap();

    if (!responseOccupied.success) {
      handleErrorResponse(responseOccupied);
    }

    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 500),
    []
  );

  useEffect(() => {
    const fetchDid = async () => {
      const response = await dispatch(
        getDidByDeviceIdAction(device.id)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }
      setDidForDevice(response.content ? response.content.did : '');

      const exist = didDropDown.some(
        (item) =>
          response.content?.did &&
          item.text === response.content.did &&
          item.value === response.content.did
      );

      if (response.content && !exist) {
        setDidDropDown((prevState) => [
          ...prevState,
          { text: response.content.did, value: response.content.did },
        ]);
      }
    };

    fetchDid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.reset({
      id: device.id,
      rev: device.additionalFields.rev,
      did: didForDevice,
      serialNumber: device.serialNumber ? device.serialNumber : '',
      sentekTH30: device.additionalFields.sentekTH30,
      sentekTHS30: device.additionalFields.sentekTHS30,
      sentekTH60: device.additionalFields.sentekTH60,
      sentekTHS60: device.additionalFields.sentekTHS60,
      sentekTH90: device.additionalFields.sentekTH90,
      sentekTHS90: device.additionalFields.sentekTHS90,
      dekagonHS1: device.additionalFields.dekagonHS1,
      dekagonHS2: device.additionalFields.dekagonHS2,
      soilPH: device.additionalSensorsValues.soilPH,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      batteryType: device.additionalFields.batteryType,
      airTempAndHumidType: device.additionalFields.airTempAndHumidType,
      windSpeed: device.additionalSensorsValues.windSpeed,
      windDirection: device.additionalSensorsValues.windDirection,
      amountOfPrecipitation:
        device.additionalSensorsValues.amountOfPrecipitation,
      solarRadiation: device.additionalSensorsValues.solarRadiation,
      commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    });
  }, [device, didForDevice, form]);

  useEffect(() => {
    const subscription = watch(async (_, { name }) => {
      try {
        if (name === 'serialNumber' || name === 'commentOnEdit') {
          debouncedChangeHandler();
          return;
        }

        await handleSubmit(onSubmit)();
      } catch (error) {
        toast.error('Error during submission');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit, debouncedChangeHandler]);

  useEffect(() => {
    const updatedDids = occupiedDids.map((did) => ({
      text: did.did,
      value: did.did,
    }));

    const exist = updatedDids.some((item) => item.value === didForDevice);

    if (didForDevice && !exist) {
      updatedDids.push({ text: didForDevice, value: didForDevice });
    }

    setDidDropDown(updatedDids);
  }, [occupiedDids, didForDevice]);

  return (
    <TableRow>
      <TableCell>{device.name}</TableCell>
      <TableCellInputForm form={form} field="serialNumber" />
      <TableCellDropdownForm
        form={form}
        items={didDropDown}
        field="did"
        isDisabled={false}
      />
      <TableCellDropdownForm
        form={form}
        items={revisionDropdown}
        field="rev"
        isDisabled={false}
      />
      <TableCell>{device.additionalFields.dcRelay}</TableCell>
      <TableCellCheckboxForm form={form} field="sentekTH30" />
      <TableCellCheckboxForm form={form} field="sentekTHS30" />
      <TableCellCheckboxForm form={form} field="sentekTH60" />
      <TableCellCheckboxForm form={form} field="sentekTHS60" />
      <TableCellCheckboxForm form={form} field="sentekTH90" />
      <TableCellCheckboxForm form={form} field="sentekTHS90" />
      <TableCellCheckboxForm form={form} field="dekagonHS1" />
      <TableCellCheckboxForm form={form} field="dekagonHS2" />
      <TableCellCheckboxForm form={form} field="soilPH" />
      <TableCellDropdownForm
        form={form}
        items={flowMeterTypes}
        field="flowMeterType"
        isDisabled={!device.additionalSensorsValues.flowMeter}
      />
      <TableCellCheckboxForm form={form} field="irrigationByTime" />
      <TableCellCheckboxForm form={form} field="irrigationByVolume" />
      <TableCellDropdownForm
        form={form}
        items={batteryTypesSENS}
        field="batteryType"
        isDisabled
      />
      <TableCellDropdownForm
        form={form}
        items={airTempAndHumidTypes}
        field="airTempAndHumidType"
        isDisabled={
          !device.additionalSensorsValues.airTemperature ||
          !device.additionalSensorsValues.airHumidity
        }
      />
      <TableCellCheckboxForm form={form} field="windSpeed" />
      <TableCellCheckboxForm form={form} field="windDirection" />
      <TableCellCheckboxForm form={form} field="amountOfPrecipitation" />
      <TableCellCheckboxForm form={form} field="solarRadiation" />
      <TableCellInputForm form={form} field="commentOnEdit" />
    </TableRow>
  );
};

export default DeliverySENSDeviceForm;
