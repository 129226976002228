import {
  ChartNoAxesCombined,
  Home,
  Leaf,
  Map,
  PanelLeft,
  UserCog2,
  Users2,
  Table,
} from 'lucide-react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '@/components/ui/button.tsx';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet.tsx';
import { resetBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type MobileNavigationLinkProps = {
  text: string;
  icon: ReactNode;
  link: string;
  isActive: boolean;
};

const MobileNavigationLink: FC<MobileNavigationLinkProps> = ({
  text,
  link,
  icon,
  isActive,
}) => {
  const dispatch = useAppDispatch();

  const onLinkClick = () => {
    dispatch(resetBreadcrumbs());
  };

  return (
    <Link
      to={link}
      className={`flex items-center gap-4 px-2.5 py-2 rounded-lg text-muted-foreground hover:text-foreground ${isActive ? 'bg-[#2b90d9] text-white' : 'text-[#2b90d9]'}`}
      onClick={onLinkClick}
    >
      {icon}
      {text}
    </Link>
  );
};

const MobileDrawerNavigation: FC = () => {
  const location = useLocation();
  const path = location.pathname;
  const [isHomeActive, setIsHomeActive] = useState(false);
  const [isUsersActive, setIsUsersActive] = useState(false);
  const [isDistributorsActive, setIsDistributorsActive] = useState(false);
  const [isParcelsActive, setIsParcelsActive] = useState(false);
  const [isFruitTypesActive, setIsFruitTypesActive] = useState(false);
  const [isShipmentActive, setIsShipmentActive] = useState(false);
  const [isStatisticsActive, setIsStatisticsActive] = useState(false);
  const [isDidActive, setIsDidActive] = useState(false);

  const isActive = (link: string) => {
    if (link === '/' && path === '/') {
      return true;
    }
    return link !== '/' && path.startsWith(link);
  };

  useEffect(() => {
    setIsHomeActive(isActive('/'));
    setIsUsersActive(isActive('/users'));
    setIsDistributorsActive(isActive('/distributors'));
    setIsParcelsActive(isActive('/parcels'));
    setIsFruitTypesActive(isActive('/fruit-types'));
    setIsShipmentActive(isActive('/delivery'));
    setIsStatisticsActive(isActive('/statistics'));
    setIsDidActive(isActive('/did'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="icon" variant="outline" className="sm:hidden">
          <PanelLeft className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="sm:max-w-xs">
        <SheetTitle className="text-xl text-[#2b90d9]">Navigacija</SheetTitle>
        <SheetDescription />
        <nav className="grid gap-6 text-lg font-medium mt-10">
          <MobileNavigationLink
            text="Početna"
            icon={
              <Home
                className={`h-5 w-5 ${isHomeActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/"
            isActive={isHomeActive}
          />
          <MobileNavigationLink
            text="Korisnici"
            icon={
              <Users2
                className={`h-5 w-5 ${isUsersActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/users"
            isActive={isUsersActive}
          />
          <MobileNavigationLink
            text="Distributeri"
            icon={
              <UserCog2
                className={`h-5 w-5 ${isDistributorsActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/distributors"
            isActive={isDistributorsActive}
          />
          <MobileNavigationLink
            text="Parcele"
            icon={
              <Map
                className={`h-5 w-5 ${isParcelsActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/parcels"
            isActive={isParcelsActive}
          />
          <MobileNavigationLink
            text="Kulture"
            icon={
              <Leaf
                className={`h-5 w-5 ${isFruitTypesActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/fruit-types"
            isActive={isFruitTypesActive}
          />
          <MobileNavigationLink
            text="Isporuka"
            icon={
              <Table
                className={`h-5 w-5 ${isShipmentActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/delivery"
            isActive={isShipmentActive}
          />
          <MobileNavigationLink
            text="Statistika"
            icon={
              <ChartNoAxesCombined
                className={`h-5 w-5 ${isStatisticsActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/statistics"
            isActive={isStatisticsActive}
          />
          <MobileNavigationLink
            text="DID"
            icon={
              <Table
                className={`h-5 w-5 ${isDidActive ? 'text-white' : 'text-[#2b90d9]'}`}
              />
            }
            link="/did"
            isActive={isDidActive}
          />
        </nav>
      </SheetContent>
    </Sheet>
  );
};

export default MobileDrawerNavigation;
