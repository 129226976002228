import { FC } from 'react';

import LoginForm from '@/components/forms/LoginForm.tsx';

const LoginPage: FC = () => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full w-full bg-[#343a40]">
        <div className="lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] rounded-lg overflow-hidden">
          <div className="flex items-center justify-center py-12 bg-white">
            <div className="mx-auto grid w-[350px] gap-6">
              <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">Prijava</h1>
                <p className="text-balance text-muted-foreground">
                  Unesite Vaše kredencijale
                </p>
              </div>
              <LoginForm />
            </div>
          </div>
          <div
            className="relative hidden bg-muted lg:block h-full bg-cover bg-center xl:w-[600px] lg:w-[500px]"
            style={{ backgroundImage: `url('/loginBg.png')` }}
          >
            <div className="flex flex-col absolute justify-center items-start inset-0 text-white xl:text-5xl lg:text-4xl font-bold px-10">
              <h1>Smart Watering Distributor</h1>
              <p className="text-lg font-light mt-5">
                Dobrodošli. Unesite vaše podatke sa leve strane.
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="sticky bottom-0 bg-[#343a40] h-70px text-white text-center">
        <span>© 2024 Smart Watering</span>
      </footer>
    </div>
  );
};

export default LoginPage;
