import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import ParcelTableRow from '@/components/layout/parcels/ParcelTableRow.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.actions.ts';
import { GetOrganizationsResponse } from '@/redux/organizations/organizations.types.ts';
import {
  getFilteredNumberOfParcels,
  getParcels,
  getTotalNumberOfParcels,
} from '@/redux/parcels/parcels.selectors.ts';

const parcelsTableHeaders: TableHeaderType[] = [
  {
    id: 'organization',
    text: 'Organizacija',
    className: 'max-lg:hidden',
    isSortDisabled: true,
  },
  {
    id: 'name',
    text: 'Parcela',
    className: '',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: '',
  },
  { id: 'country', text: 'Država', className: 'max-md:hidden' },
  { id: 'lastSync', text: 'Sinhronizovan', className: 'max-md:hidden' },
  {
    id: 'actions',
    text: '',
    className: '',
    isSortDisabled: true,
  },
];

type ParcelsTableSectionProps = {
  page: number;
  rowsPerPage: number;
  onColumnClick: (column: string) => void;
  onPageChangeClick: (selectedPage: number) => void;
  onDeleteParcel: (parcelId: string) => void;
  onRowsPerPageChange: (selectedRowsPerPage: number) => void;
};

const ParcelsTableSection: FC<ParcelsTableSectionProps> = ({
  page,
  rowsPerPage,
  onColumnClick,
  onPageChangeClick,
  onDeleteParcel,
  onRowsPerPageChange,
}) => {
  const parcels = useAppSelector(getParcels);
  const totalNumberOfParcels = useAppSelector(getTotalNumberOfParcels);
  const filteredNumberOfParcels = useAppSelector(getFilteredNumberOfParcels);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetOrganizationsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await dispatch(
        getOrganizations(localStorage.getItem('id'))
      ).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle>Parcele</CardTitle>
      </CardHeader>
      <CardContent className="max-sm:p-2">
        <Table>
          <CustomTableHeader
            tableName="parcels"
            tableHeaders={parcelsTableHeaders}
            onHeaderCellClick={onColumnClick}
          />
          <TableBody>
            {parcels.map((parcel, index) => (
              <ParcelTableRow
                key={`parcels-table-data-parcel-${index + 1}`}
                parcel={parcel}
                onParcelDelete={onDeleteParcel}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <PaginationSection
          page={page}
          rowsPerPage={rowsPerPage}
          filteredNumber={filteredNumberOfParcels}
          totalNumber={totalNumberOfParcels}
          onPageChangeClick={onPageChangeClick}
          entity="parcela"
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </CardFooter>
    </Card>
  );
};

export default ParcelsTableSection;
