import { ListFilter } from 'lucide-react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import FilterCustomSelect from '@/components/controls/FilterCustomSelect.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  getCountriesForUsers,
  getPlacesForUsers,
  getYearsOfCreationForUsers,
} from '@/redux/users/user.selectors.ts';
import {
  getUsersCountries,
  getUsersPlaces,
  getUsersYearsOfCreation,
} from '@/redux/users/users.actions.ts';
import {
  GetUsersCountriesResponse,
  GetUsersPlacesResponse,
  GetUsersYearsOfCreationResponse,
} from '@/redux/users/users.types.ts';

type UsersFilterSheetProps = {
  selectedCountry: string;
  selectedPlace: string;
  selectedPaidSubscription: string;
  selectedYearOfCreation: string;
  onFilterChange: (filter: string, value: string) => void;
  resetFilters: () => void;
};

const UsersFilterSheet: FC<UsersFilterSheetProps> = ({
  selectedCountry,
  selectedPlace,
  selectedPaidSubscription,
  selectedYearOfCreation,
  onFilterChange,
  resetFilters,
}) => {
  const dispatch = useAppDispatch();
  const distributorId = localStorage.getItem('id');
  const router = useNavigate();
  const countries = useAppSelector(getCountriesForUsers);
  const places = useAppSelector(getPlacesForUsers);
  const yearsOfCreation = useAppSelector(getYearsOfCreationForUsers);

  const countriesForSelect = countries.map((country) => {
    return { value: country.country, label: country.country };
  });
  const placesForSelect = places.map((place) => {
    return { value: place.place, label: place.place };
  });
  const yearsForSelect = yearsOfCreation.map((year) => {
    return { value: year.year, label: year.year };
  });
  const paidSubscriptionForSelect = [
    { value: 'true', label: 'Plaćena pretplata' },
    { value: 'false', label: 'Nije plaćena pretplata' },
  ];

  const handleErrorResponse = (
    response:
      | GetUsersCountriesResponse
      | GetUsersPlacesResponse
      | GetUsersYearsOfCreationResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUsersYearsOfCreation = async () => {
      // @ts-ignore
      const response = await dispatch(
        getUsersYearsOfCreation(`?distributorId='${distributorId}'`)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    const fetchUsersPlaces = async () => {
      // @ts-ignore
      const response = await dispatch(
        getUsersPlaces(`?distributorId='${distributorId}'`)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    const fetchUsersCountries = async () => {
      // @ts-ignore
      const response = await dispatch(
        getUsersCountries(`?distributorId='${distributorId}'`)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsersCountries();
    fetchUsersPlaces();
    fetchUsersYearsOfCreation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 gap-1">
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="text-start">Filteri</SheetTitle>
          <SheetDescription className="text-start">
            Odaberite filtere koje želite da primenite
          </SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4 mt-3">
          <FilterCustomSelect
            labelText="Država"
            changeField="realSelectedCountry"
            selectedValue={selectedCountry}
            onFilterChange={onFilterChange}
            filterValues={countriesForSelect}
            placeholder="Odaberite državu"
          />
          <FilterCustomSelect
            labelText="Mesto"
            changeField="realSelectedPlace"
            selectedValue={selectedPlace}
            onFilterChange={onFilterChange}
            filterValues={placesForSelect}
            placeholder="Odaberite mesto"
          />
          <FilterCustomSelect
            labelText="Plaćena pretplata"
            changeField="realSelectedPaidSubscription"
            selectedValue={selectedPaidSubscription}
            onFilterChange={onFilterChange}
            filterValues={paidSubscriptionForSelect}
            placeholder="Odaberite plaćenu pretplatu"
          />
          <FilterCustomSelect
            labelText="Godina kreiranja"
            changeField="realSelectedYearOfCreation"
            selectedValue={selectedYearOfCreation}
            onFilterChange={onFilterChange}
            filterValues={yearsForSelect}
            placeholder="Odaberite godinu kreiranja"
          />
        </div>
        <SheetFooter>
          <SheetClose asChild>
            <Button
              type="button"
              className="mt-5 touch-pinch-zoom"
              onClick={resetFilters}
            >
              Resetuj filtere
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default UsersFilterSheet;
