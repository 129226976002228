import { zodResolver } from '@hookform/resolvers/zod';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import TableCellCheckboxForm from '@/components/forms/delivery/controls/TableCellCheckboxForm.tsx';
import TableCellDropdownForm from '@/components/forms/delivery/controls/TableCellDropdownForm.tsx';
import TableCellInputForm from '@/components/forms/delivery/controls/TableCellInputForm.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { patchFERTDelivery } from '@/redux/device/devices.actions.ts';
import {
  DeviceRevision,
  FERTDevice,
  GetFERTDeviceResponse,
  PatchFERTRequest,
} from '@/redux/device/devices.types.ts';
import {
  getDidByDeviceId as getDidByDeviceIdAction,
  getOccupiedDidsWithoudDevice,
} from '@/redux/did/did.actions.ts';
import { getOccupiedDidsWithoutDevice } from '@/redux/did/did.selectors.ts';
import {
  AllDidsResponse,
  GetDidByDeviceIdResponse,
} from '@/redux/did/did.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { communicationModules, flowMeterTypes } from '@/utils/device.util.ts';
import { patchFERTSchema } from '@/zod/types.ts';

type DeliveryFERTDeviceFormProps = {
  device: FERTDevice;
  revisionsFERT: DeviceRevision[];
};

export type PatchFERTFormData = {
  id: string;
  rev: string;
  serialNumber: string;
  did: string;
  mainPumpRelay24: boolean;
  mixerRelay24: boolean;
  communicationModule: string;
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
  pHControl: boolean;
  ecControl: boolean;
  flowProportional: boolean;
  fixedPumpPowerMod2: boolean;
  pHWater: boolean;
  flowMeterType: string;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  ecSensor: boolean;
  waterLevelSensor: boolean;
  commentOnEdit: string;
};

const DeliveryFERTDeviceForm: FC<DeliveryFERTDeviceFormProps> = ({
  device,
  revisionsFERT,
}) => {
  const occupiedDids = useAppSelector(getOccupiedDidsWithoutDevice);
  const dispatch = useAppDispatch();
  const [didForDevice, setDidForDevice] = useState<string>('');
  const [didDropDown, setDidDropDown] = useState<DropDownItems[]>(
    occupiedDids.map((did) => {
      return {
        text: did.did,
        value: did.did,
      };
    })
  );
  const router = useNavigate();
  const form = useForm({
    resolver: zodResolver(patchFERTSchema),
    defaultValues: {
      id: device.id,
      rev: device.additionalFields.rev,
      did: didForDevice,
      serialNumber: '',
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      communicationModule: device.communicationModule,
      supplementOne: device.supplement.supplementOne,
      supplementTwo: device.supplement.supplementTwo,
      supplementThree: device.supplement.supplementThree,
      supplementFour: device.supplement.supplementFour,
      supplementFive: device.supplement.supplementFive,
      pHControl: device.additionalFields.pHControl,
      ecControl: device.additionalFields.ecControl,
      flowProportional: device.additionalFields.flowProportional,
      fixedPumpPowerMod2: device.additionalFields.fixedPumpPowerMod2,
      pHWater: device.additionalFields.pHWater,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      ecSensor: device.additionalSensorsValues.ecSensor,
      waterLevelSensor: device.additionalSensorsValues.waterLevelSensor,
      commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    },
  });
  const { handleSubmit, watch } = form;

  const revisionDropdown: DropDownItems[] = revisionsFERT.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const handleErrorResponse = (
    response: GetFERTDeviceResponse | GetDidByDeviceIdResponse | AllDidsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = async (data: PatchFERTFormData) => {
    const dataToSend: PatchFERTRequest = {
      device,
      dataToUpdate: data,
    };

    // @ts-ignore
    const response = await dispatch(patchFERTDelivery(dataToSend)).unwrap();
    setDidForDevice(data.did);

    const responseOccupied = await dispatch(
      getOccupiedDidsWithoudDevice()
    ).unwrap();

    if (!responseOccupied.success) {
      handleErrorResponse(responseOccupied);
    }

    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  useEffect(() => {
    const fetchDid = async () => {
      const response = await dispatch(
        getDidByDeviceIdAction(device.id)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }
      setDidForDevice(response.content ? response.content.did : '');

      const exist = didDropDown.some(
        (item) =>
          response.content?.did &&
          item.text === response.content.did &&
          item.value === response.content.did
      );

      if (response.content && !exist) {
        setDidDropDown((prevState) => [
          ...prevState,
          { text: response.content.did, value: response.content.did },
        ]);
      }
    };

    fetchDid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.reset({
      id: device.id,
      rev: device.additionalFields.rev,
      did: didForDevice,
      serialNumber: device.serialNumber ? device.serialNumber : '',
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      communicationModule: device.communicationModule,
      supplementOne: device.supplement.supplementOne,
      supplementTwo: device.supplement.supplementTwo,
      supplementThree: device.supplement.supplementThree,
      supplementFour: device.supplement.supplementFour,
      supplementFive: device.supplement.supplementFive,
      pHControl: device.additionalFields.pHControl,
      ecControl: device.additionalFields.ecControl,
      flowProportional: device.additionalFields.flowProportional,
      fixedPumpPowerMod2: device.additionalFields.fixedPumpPowerMod2,
      pHWater: device.additionalFields.pHWater,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      ecSensor: device.additionalSensorsValues.ecSensor,
      waterLevelSensor: device.additionalSensorsValues.waterLevelSensor,
      commentOnEdit: device.commentOnEdit ? device.commentOnEdit : '',
    });
  }, [device, form, didForDevice]);

  useEffect(() => {
    const subscription = watch(async (_, { name }) => {
      try {
        if (name === 'serialNumber' || name === 'commentOnEdit') {
          debouncedChangeHandler();
          return;
        }

        await handleSubmit(onSubmit)();
      } catch (error) {
        toast.error('Error during submission');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit, debouncedChangeHandler]);

  useEffect(() => {
    const updatedDids = occupiedDids.map((did) => ({
      text: did.did,
      value: did.did,
    }));

    const exist = updatedDids.some((item) => item.value === didForDevice);

    if (didForDevice && !exist) {
      updatedDids.push({ text: didForDevice, value: didForDevice });
    }

    setDidDropDown(updatedDids);
  }, [occupiedDids, didForDevice]);

  return (
    <TableRow>
      <TableCell>{device.name}</TableCell>
      <TableCellInputForm form={form} field="serialNumber" />
      <TableCellDropdownForm
        form={form}
        items={didDropDown}
        field="did"
        isDisabled={false}
      />
      <TableCellDropdownForm
        form={form}
        items={revisionDropdown}
        field="rev"
        isDisabled={false}
      />
      <TableCell>{device.additionalFields.acRelay}</TableCell>
      <TableCellCheckboxForm form={form} field="mainPumpRelay24" />
      <TableCellCheckboxForm form={form} field="mixerRelay24" />
      <TableCellCheckboxForm form={form} field="supplementOne" />
      <TableCellCheckboxForm form={form} field="supplementTwo" />
      <TableCellCheckboxForm form={form} field="supplelemtThree" />
      <TableCellCheckboxForm form={form} field="supplementFour" />
      <TableCellCheckboxForm form={form} field="supplementFive" />
      <TableCellCheckboxForm form={form} field="pHControl" />
      <TableCellCheckboxForm form={form} field="ecControl" />
      <TableCellCheckboxForm form={form} field="flowProportional" />
      <TableCellCheckboxForm form={form} field="fixedPumpPowerMod2" />
      <TableCellCheckboxForm form={form} field="pHWater" />
      <TableCellCheckboxForm form={form} field="ecSensor" />
      <TableCellDropdownForm
        form={form}
        items={flowMeterTypes}
        field="flowMeterType"
        isDisabled={!device.additionalSensorsValues.flowMeter}
      />
      <TableCellCheckboxForm form={form} field="irrigationByTime" />
      <TableCellCheckboxForm form={form} field="irrigationByVolume" />
      <TableCellCheckboxForm form={form} field="waterLevelSensor" />
      <TableCellDropdownForm
        form={form}
        items={communicationModules}
        field="communicationModule"
        isDisabled
      />
      <TableCellInputForm form={form} field="commentOnEdit" />
    </TableRow>
  );
};

export default DeliveryFERTDeviceForm;
