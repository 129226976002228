import { Check, ChevronsUpDown } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeliveryPageDevicesTables from '@/components/layout/delivery/DeliveryPageDevicesTables.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command.tsx';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { cn } from '@/lib/utils.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getAllDevicesRevisions,
  getDevicesForUser,
} from '@/redux/device/devices.actions.ts';
import {
  GetDevicesForUserResponse,
  GetDevicesRevisionsResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getOrganizations as getOrganizationsAction } from '@/redux/organizations/organizations.actions.ts';
import { getParcelsForOrganization as getParcelsForOrganizationAction } from '@/redux/organizations/organizations.actions.ts';
import {
  getOrganizations,
  getParcelsForOrganization,
} from '@/redux/organizations/organizations.selectors.ts';
import { GetOrganizationsResponse } from '@/redux/organizations/organizations.types.ts';
import { GetUserDropdownResponse } from '@/redux/users/users.types.ts';

type SelectUserSectionProps = {
  selectedUserId: string;
  onUserSelectChange: (value: string) => void;
};

type SelectUserParcelSectionProps = {
  selectedParcelId: string;
  onParcelSelectChange: (value: string) => void;
};

const SelectUserSection: FC<SelectUserSectionProps> = ({
  selectedUserId,
  onUserSelectChange,
}) => {
  const organizations = useAppSelector(getOrganizations);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            'px-5 py-3 text-base flex justify-between items-center min-w-[200px] font-light',
            !selectedUserId && 'text-muted-foreground'
          )}
        >
          {selectedUserId
            ? organizations.find((item) => item.id === selectedUserId)?.name
            : 'Odaberite organizaciju'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[300px] p-0 mr-2">
        <Command>
          <CommandInput placeholder="Pretražite..." />
          <CommandList>
            <CommandEmpty>Nema rezultata pretrage.</CommandEmpty>
            <CommandGroup>
              {organizations.map((item) => (
                <CommandItem
                  value={item.name}
                  key={item.name}
                  onSelect={() => {
                    onUserSelectChange(item.id);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      item.id === selectedUserId ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {item.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const SelectUserParcelSection: FC<SelectUserParcelSectionProps> = ({
  selectedParcelId,
  onParcelSelectChange,
}) => {
  const parcelsForUser = useAppSelector(getParcelsForOrganization);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            'px-5 py-3 text-base flex justify-between items-center min-w-[200px] font-light',
            !selectedParcelId && 'text-muted-foreground'
          )}
        >
          {selectedParcelId
            ? parcelsForUser.find((item) => item.id === selectedParcelId)?.name
            : 'Odaberite parcelu'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[300px] p-0 mr-2">
        <Command>
          <CommandInput placeholder="Pretražite..." />
          <CommandList>
            <CommandEmpty>Nema rezultata pretrage.</CommandEmpty>
            <CommandGroup>
              {parcelsForUser.map((item) => (
                <CommandItem
                  value={item.name}
                  key={item.name}
                  onSelect={() => {
                    onParcelSelectChange(item.id);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      item.id === selectedParcelId ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {item.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const DeliveryPageContent: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedParcelId, setSelectedParcelId] = useState<string>('');

  const handleErrorResponse = (
    response:
      | GetUserDropdownResponse
      | GetDevicesRevisionsResponse
      | GetDevicesForUserResponse
      | GetOrganizationsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onParcelChange = async (value: string, userId?: string) => {
    setSelectedParcelId(value);

    const params = `?userId=${selectedUserId || userId}&parcelId=${value}`;
    // @ts-ignore
    const response = await dispatch(getDevicesForUser(params)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  const onUserChange = async (value: string) => {
    setSelectedParcelId(null);
    setSelectedUserId(value);
    // @ts-ignore
    const response = await dispatch(
      getParcelsForOrganizationAction(value)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    if (response.content.length === 1) {
      setSelectedParcelId(response.content[0].id);
      await onParcelChange(response.content[0].id, value);
    }
  };

  useEffect(() => {
    const fetchAllDevicesRevisions = async () => {
      // @ts-ignore
      const response = await dispatch(getAllDevicesRevisions()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    const fetchOrganizations = async () => {
      const response = await dispatch(
        getOrganizationsAction(localStorage.getItem('id'))
      ).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchOrganizations();
    fetchAllDevicesRevisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0" className="overflow-x-auto">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3 max-md:flex-col gap-4 max-md:items-start">
          <p className="max-sm:text-start text-center">Uredjaji za korisnika</p>
          <div className="flex flex-row max-md:flex-col gap-2">
            <SelectUserSection
              selectedUserId={selectedUserId}
              onUserSelectChange={onUserChange}
            />
            <SelectUserParcelSection
              selectedParcelId={selectedParcelId}
              onParcelSelectChange={onParcelChange}
            />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {selectedParcelId ? <DeliveryPageDevicesTables /> : null}
      </CardContent>
    </Card>
  );
};

export default DeliveryPageContent;
