import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getParcelStatistics } from '@/redux/statistics/statistics.actions.ts';
import {
  getNumberOfActiveParcels,
  getNumberOfParcels,
} from '@/redux/statistics/statistics.selectors.ts';
import { GetParcelStatisticsResponse } from '@/redux/statistics/statistics.types.ts';

const numberOfActiveParcelsChartConfig = {
  value: {
    label: 'Broj parcela ',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const ParcelStatistic: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const numberOfActiveParcels = useAppSelector(getNumberOfActiveParcels);
  const numberOfParcels = useAppSelector(getNumberOfParcels);
  const numberOfActiveParcelsDataChart = [
    { name: 'Broj aktivnih parcela', value: numberOfActiveParcels },
    { name: 'Broj parcela u opticaju', value: numberOfParcels },
  ];

  const handleErrorResponse = (response: GetParcelStatisticsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchParcelStatistics = async () => {
      // @ts-ignore
      const response = await dispatch(
        getParcelStatistics(localStorage.getItem('id'))
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchParcelStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Statistika parcela</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Broj parcela</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={numberOfActiveParcelsChartConfig}>
                  <BarChart
                    accessibilityLayer
                    data={numberOfActiveParcelsDataChart}
                  >
                    <CartesianGrid vertical={false} />
                    <YAxis
                      type="number"
                      dataKey="value"
                      hide={false}
                      axisLine={false}
                    />
                    <XAxis
                      dataKey="name"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                    />
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Bar dataKey="value" fill="#2b90d9" radius={8} />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default ParcelStatistic;
