import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import NotSubscribedUserTableRow from '@/components/layout/statistics/NotSubscribedUserTableRow.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getAllNotSubscribedUsers } from '@/redux/statistics/statistics.actions.ts';
import { getNotSubscribedUsers } from '@/redux/statistics/statistics.selectors.ts';
import { AllNotSubscribedUsersResponse } from '@/redux/statistics/statistics.types.ts';

const userTableHeaders: TableHeaderType[] = [
  {
    id: 'full_name',
    text: 'Korisnik',
    className: 'max-sm:hidden',
    isSortDisabled: true,
  },
  {
    id: 'wayOfInforming',
    text: 'Način informisanja',
    className: 'max-2xl:hidden',
    isSortDisabled: true,
  },
  { id: 'email', text: 'Email', className: '', isSortDisabled: true },
  {
    id: 'phoneNumber',
    text: 'Telefon',
    className: 'max-xl:hidden',
    isSortDisabled: true,
  },
  {
    id: 'dateOfPayment',
    text: 'Datum plaćanja',
    className: 'max-2xl:hidden',
    isSortDisabled: true,
  },
];

const NotSubscribedTable: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const users = useAppSelector(getNotSubscribedUsers);

  const handleErrorResponse = (response: AllNotSubscribedUsersResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchNotSubscribedUsers = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAllNotSubscribedUsers(localStorage.getItem('id'))
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchNotSubscribedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-users">
      <CardHeader>
        <CardTitle>Nepretplaćeni korisnici</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <CustomTableHeader
            tableName="users"
            tableHeaders={userTableHeaders}
          />
          <TableBody>
            {users.map((user, index) => (
              <NotSubscribedUserTableRow
                key={`user-table-data-user-${index + 1}`}
                user={user}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
export default NotSubscribedTable;
