import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FetchUser,
  ParcelForUser,
  UserForDropdown,
  UsersCountries,
  UsersPlaces,
  UsersYearsOfCreation,
} from './users.types.ts';

import { compareByName } from '@/utils/sort.ts';

type UsersInitialState = {
  users: FetchUser[];
  totalNumberOfUsers: number;
  filteredNumberOfUsers: number;
  usersForDropdown: UserForDropdown[];
  parcelsForUser: ParcelForUser[];
  usersCountries: UsersCountries[];
  usersPlaces: UsersPlaces[];
  usersYearsOfCreation: UsersYearsOfCreation[];
};

const usersInitialState: UsersInitialState = {
  users: [],
  totalNumberOfUsers: 0,
  filteredNumberOfUsers: 0,
  usersForDropdown: [],
  parcelsForUser: [],
  usersCountries: [],
  usersPlaces: [],
  usersYearsOfCreation: [],
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: usersInitialState,
  reducers: {
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    setUsers: (state, action: PayloadAction<FetchUser[]>) => {
      state.users = action.payload || [];
    },
    setTotalNumberOfUsers: (state, action: PayloadAction<number>) => {
      state.totalNumberOfUsers = action.payload;
    },
    setFilteredNumberOfUsers: (state, action: PayloadAction<number>) => {
      state.filteredNumberOfUsers = action.payload;
    },
    setUpdatedUser: (state, action: PayloadAction<FetchUser>) => {
      const foundIndex = state.users.findIndex(
        (user) => user.id === action.payload.id
      );

      if (foundIndex !== -1) {
        state.users[foundIndex] = action.payload;
      }
    },
    setUsersForDropdown: (state, action: PayloadAction<UserForDropdown[]>) => {
      state.usersForDropdown = action.payload;
    },
    removeDropdownUser: (state, action: PayloadAction<string>) => {
      state.usersForDropdown = state.usersForDropdown.filter(
        (user) => user.id !== action.payload
      );
    },
    addDropdownUser: (state, action: PayloadAction<FetchUser>) => {
      state.usersForDropdown = [
        ...state.usersForDropdown,
        { id: action.payload.id, name: action.payload.full_name },
      ].sort(compareByName);
    },
    setParcelsForUser: (state, action: PayloadAction<ParcelForUser[]>) => {
      state.parcelsForUser = action.payload;
    },
    removeDeletedUser: (state, action: PayloadAction<string>) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    setUsersCountries: (state, action: PayloadAction<UsersCountries[]>) => {
      state.usersCountries = action.payload || [];
    },
    setUsersPlaces: (state, action: PayloadAction<UsersPlaces[]>) => {
      state.usersPlaces = action.payload || [];
    },
    setUsersYearsOfCreation: (
      state,
      action: PayloadAction<UsersYearsOfCreation[]>
    ) => {
      state.usersYearsOfCreation = action.payload || [];
    },
  },
});

export const {
  addUser,
  setUsers,
  setTotalNumberOfUsers,
  setUpdatedUser,
  setFilteredNumberOfUsers,
  setUsersForDropdown,
  removeDropdownUser,
  addDropdownUser,
  setParcelsForUser,
  removeDeletedUser,
  setUsersCountries,
  setUsersPlaces,
  setUsersYearsOfCreation,
} = usersSlice.actions;
export default usersSlice.reducer;
